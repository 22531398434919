var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.form)?_c('validation-observer',{ref:"refFormCreate"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',{attrs:{"label":"โมเดล"}},[_c('validation-provider',{attrs:{"name":"โมเดล","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"disabled":!_vm.canEdit,"placeholder":"โปรดระบุ"},model:{value:(_vm.form.finance_car_model),callback:function ($$v) {_vm.$set(_vm.form, "finance_car_model", $$v)},expression:"form.finance_car_model"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2230441236)})],1)],1),_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',{attrs:{"label":"รหัสอ้างอิงไฟแนนซ์"}},[_c('validation-provider',{attrs:{"name":"รหัสอ้างอิงไฟแนนซ์"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"disabled":!_vm.canEdit},model:{value:(_vm.form.finance_car_code),callback:function ($$v) {_vm.$set(_vm.form, "finance_car_code", $$v)},expression:"form.finance_car_code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2202725948)})],1)],1),_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',{attrs:{"label":"ยอดจัด"}},[_c('validation-provider',{attrs:{"name":"ยอดจัด","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input-currency-widget',{attrs:{"error":errors.length > 0 ? false : null,"disabled":!_vm.canEdit,"placeholder":"โปรดระบุ"},model:{value:(_vm.form.finance_car_item_price),callback:function ($$v) {_vm.$set(_vm.form, "finance_car_item_price", $$v)},expression:"form.finance_car_item_price"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2553560140)})],1)],1),_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',{attrs:{"label":"ออกรถเริ่มต้น"}},[_c('validation-provider',{attrs:{"name":"ออกรถเริ่มต้น","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input-currency-widget',{attrs:{"error":errors.length > 0 ? false : null,"disabled":!_vm.canEdit,"placeholder":"โปรดระบุ"},model:{value:(_vm.form.down_payment),callback:function ($$v) {_vm.$set(_vm.form, "down_payment", $$v)},expression:"form.down_payment"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2397714052)})],1)],1),_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',{attrs:{"label":"ไฟแนนซ์"}},[_c('validation-provider',{attrs:{"name":"ไฟแนนซ์","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:[errors[0] ? 'error' : null],attrs:{"reduce":function (op) { return op.id; },"options":_vm.optionsFinance,"disabled":!_vm.canEdit,"clearable":false,"get-option-label":function (op) { return ((op.finance_name) + " (" + (op.finance_code) + ")"); },"placeholder":"โปรดระบุ"},model:{value:(_vm.form.finance_data_id),callback:function ($$v) {_vm.$set(_vm.form, "finance_data_id", $$v)},expression:"form.finance_data_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4294756213)})],1)],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }