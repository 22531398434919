<template>
  <div>
    <validation-observer
      v-if="form"
      ref="refFormCreate"
    >
      <b-form>
        <b-row>
          <b-col sm="12">
            <b-form-group label="โมเดล">
              <validation-provider
                #default="{ errors }"
                name="โมเดล"
                rules="required"
              >
                <b-form-input
                  v-model="form.finance_car_model"
                  :state="errors.length > 0 ? false : null"
                  :disabled="!canEdit"
                  placeholder="โปรดระบุ"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="12">
            <b-form-group label="รหัสอ้างอิงไฟแนนซ์">
              <validation-provider
                #default="{ errors }"
                name="รหัสอ้างอิงไฟแนนซ์"
              >
                <b-form-input
                  v-model="form.finance_car_code"
                  :state="errors.length > 0 ? false : null"
                  :disabled="!canEdit"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="12">
            <b-form-group label="ยอดจัด">
              <validation-provider
                #default="{ errors }"
                name="ยอดจัด"
                rules="required"
              >
                <!-- <b-form-input
                  v-model="form.finance_car_item_price"
                  :state="errors.length > 0 ? false : null"
                  :disabled="!canEdit"
                  type="number"
                  number
                  placeholder="โปรดระบุ"
                /> -->
                <input-currency-widget
                  v-model="form.finance_car_item_price"
                  :error="errors.length > 0 ? false : null"
                  :disabled="!canEdit"
                  placeholder="โปรดระบุ"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="12">
            <b-form-group label="ออกรถเริ่มต้น">
              <validation-provider
                #default="{ errors }"
                name="ออกรถเริ่มต้น"
                rules="required"
              >
                <!-- <b-form-input
                  v-model="form.down_payment"
                  :state="errors.length > 0 ? false : null"
                  :disabled="!canEdit"
                  type="number"
                  number
                  placeholder="โปรดระบุ"
                /> -->
                <input-currency-widget
                  v-model="form.down_payment"
                  :error="errors.length > 0 ? false : null"
                  :disabled="!canEdit"
                  placeholder="โปรดระบุ"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="12">
            <b-form-group label="ไฟแนนซ์">
              <validation-provider
                #default="{ errors }"
                name="ไฟแนนซ์"
                rules="required"
              >
                <v-select
                  v-model="form.finance_data_id"
                  :reduce="op => op.id"
                  :options="optionsFinance"
                  :disabled="!canEdit"
                  :class="[errors[0] ? 'error' : null]"
                  :clearable="false"
                  :get-option-label="op => `${op.finance_name} (${op.finance_code})`"
                  placeholder="โปรดระบุ"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!-- <pre>{{ form }}</pre> -->
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import { required } from '@validations'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  props: {
    formData: {
      type: Object,
      default: () => null,
    },
    canEdit: {
      type: Boolean,
      default: true,
    },
    optionsFinance: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      form: null,
    }
  },
  watch: {
    formData(newVal) {
      this.form = { ...newVal }
    },
  },
  created() {
    this.form = { ...this.formData, finance_car_code: this.formData.finance_car_code ?? '0' }
  },
  methods: {
    getFormData() {
      return this.form
    },
    async validateForm() {
      const v = await this.$refs.refFormCreate.validate()
      return v
    },
  },
}
</script>

<style lang="scss" scoped></style>
