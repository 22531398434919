<template>
  <div>
    <div v-if="cCanCreateOrUpload">
      <!-- <button-loading-widget
        text="เพิ่มข้อมูลผ่านฟอร์ม"
        size="md"
        variant="primary"
        block
        @click="addManualRateFinance"
      />
      <p class="text-center text-muted my-1">หรือ</p> -->
      <b-overlay :show="pageLoading">
        <upload-file-excel-widget @uploadSuccess="onUploadFile" />
      </b-overlay>
    </div>

    <table-bt-widget
      v-if="itemsUpload.length > 0"
      :rows="itemsUpload"
      :columns="itemsColumn"
      :show-add-button="false"
      :loading="itemsUploadLoading"
      delete-able
      @clickDelete="onDeleteItemUpload"
    >
      <template #headerTitle>
        <div class="d-flex align-items-center mb-50 mb-sm-0">
          <h5 class="m-0 mr-50">ข้อมูลเรทไฟแนนซ์รอการอัปโหลด {{ itemsUpload[0].local_finance_preview }}</h5>
          <button-loading-widget
            icon="XIcon"
            variant="danger"
            class="btn-icon mr-50"
            style="padding: 0.3rem"
            size="sm"
            :loading="itemsUploadLoading"
            @click="onClickCloseTableUpload('itemsUpload')"
          />
          <button-loading-widget
            icon="CheckIcon"
            variant="success"
            class="btn-icon"
            style="padding: 0.3rem"
            size="sm"
            :loading="itemsUploadLoading"
            @click="onSaveItemsUpload"
          />
        </div>
      </template>
    </table-bt-widget>

    <table-bt-widget
      v-if="rejectItemsUpload.length > 0"
      :rows="rejectItemsUpload"
      :columns="cRejectColumns"
      :show-add-button="false"
      :loading="rejectItemsLoading"
      edit-able
      @clickEdit="onClickEditRejectItemUpload"
    >
      <template #headerTitle>
        <div class="d-flex align-items-center mb-50 mb-sm-0">
          <h5 class="m-0 mr-50">ข้อมูลเรทไฟแนนซ์รอการอัปโหลด {{ rejectItemsUpload[0].local_finance_preview }}</h5>
          <button-loading-widget
            icon="XIcon"
            variant="danger"
            class="btn-icon mr-50"
            style="padding: 0.3rem"
            size="sm"
            :loading="rejectItemsLoading"
            @click="onClickCloseTableUpload('rejectItemsUpload')"
          />
          <button-loading-widget
            icon="CheckIcon"
            variant="success"
            class="btn-icon"
            style="padding: 0.3rem"
            size="sm"
            :loading="rejectItemsLoading"
            @click="onSaveRejectItemsUpload"
          />
        </div>
      </template>

      <template #has_motorbike_data="{ data }">
        <b-badge
          v-if="data.value"
          variant="success"
        >
          เพิ่มแล้ว
        </b-badge>
        <b-badge v-else> ยังไม่ได้เพิ่ม </b-badge>
      </template>
    </table-bt-widget>
    <hr />
    <!-- <h3 class="mb-1">เรทไฟแนนซ์</h3> -->
    <table-bt-server-side-widget
      ref="refTableRateFinance"
      url="api/finance-car-item-data/index"
      :columns="cFinanceColumns"
      :loading-sync="financeRateLoading"
      title="เรทไฟแนนซ์"
      edit-able
      delete-able
      @clickAdd="addManualRateFinance"
      @clickEdit="onClickEditRateFinance"
      @clickDelete="onClickDeleteRateFinance"
    >
    </table-bt-server-side-widget>

    <b-modal
      ref="refModalFormRateFinance"
      title="เพิ่มข้อมูลเรท"
      no-close-on-backdrop
      no-close-on-esc
      :hide-footer="false"
      ok-only
      :ok-title="formRateFinance.id ? 'แก้ไข' : 'เพิ่ม'"
      @ok="onConfirmModalFormRateFinance"
    >
      <form-rate-finance
        ref="refFormRateFinance"
        :options-finance="financeList"
        :form-data="formRateFinance"
      />
    </b-modal>

    <b-modal
      ref="refModalFormMotorbike"
      title="เพิ่มข้อมูลรถ"
      no-close-on-backdrop
      no-close-on-esc
      :hide-footer="false"
      size="xl"
      ok-only
      ok-title="เพิ่ม"
      @ok="onConfirmModalFormMotorbike"
    >
      <form-motorbike
        ref="refFormMotorbike"
        :form-data="formMotorbike"
      />
    </b-modal>
    <!-- <pre>{{ financeList }}</pre> -->
  </div>
</template>

<script>
import FormMotorbike from '../motorbike-management/components/FormMotorbike.vue'
import FormRateFinance from './components/FormRateFinance.vue'

export default {
  components: {
    FormMotorbike,
    FormRateFinance,
  },
  data() {
    return {
      itemsUpload: [],
      itemsUploadLoading: false,
      itemsColumn: [
        { key: 'finance_car_model', label: 'โมเดล' },
        { key: 'finance_car_code', label: 'รหัสอ้างอิงไฟแนนซ์', tdClass: 'text-center', thClass: 'text-center' },
        {
          key: 'finance_car_item_price',
          label: 'ยอดจัด',
          tdClass: 'text-center',
          thClass: 'text-center',
          formatter: value => this.gFormatNumberToCurrency(value),
        },
        {
          key: 'down_payment',
          label: 'ออกรถเริ่มต้น',
          tdClass: 'text-center',
          thClass: 'text-center',
          formatter: value => this.gFormatNumberToCurrency(value),
        },
      ],
      keyUploadFileRequired: ['MODELCODE', 'CODE', 'YODJUD', 'DEFAULT'],
      rejectItemsUpload: [],
      rejectItemsLoading: false,
      formRateFinance: {
        finance_car_model: '',
        finance_car_code: '',
        finance_car_item_price: '',
        down_payment: '',
        finance_data_id: null,
      },
      formMotorbike: null,
      financeRateList: [],
      financeRateLoading: false,
      financeList: [],
      pageLoading: false,
    }
  },
  computed: {
    cCanCreateOrUpload() {
      if (this.itemsUpload.length > 0 || this.rejectItemsUpload.length > 0) {
        return false
      }
      return true
    },
    cRejectColumns() {
      return [...this.itemsColumn, { key: 'has_motorbike_data', label: 'สถานะข้อมูลรถ' }]
    },
    cFinanceColumns() {
      return [{ key: 'finance_data_name', label: 'ไฟแนนซ์' }, ...this.itemsColumn]
    },
  },
  async created() {
    this.pageLoading = true
    await this.getFinanceList()
    this.pageLoading = false
  },
  methods: {
    createFormObject(model = '', localId) {
      return {
        local_id: localId,
        car_model: model,
        car_name_th: '',
        car_name_en: '',
        car_size: '',
        frame_number: '',
        selling_price: 0,
        image_id: 0,
        cost_price: 0,
        register_cost: 0,
        act_cost: 0,
        transfer_cost: 0,
        usable: true,
      }
    },
    onUploadFile(data, file) {
      const firstData = [...data][0]

      const checkFileValid = this.keyUploadFileRequired.every(value => value in firstData)

      if (!checkFileValid) {
        this.gDisplayToast('โปรดตรวจสอบไฟล์', 'รูปแบบข้อมูลในไฟล์ที่อัปโหลดไม่ถูกต้อง', 'danger')
        return
      }

      // eslint-disable-next-line prefer-destructuring
      const fileUploadName = `${file.name}`.split('.')[0].split('_')[0]
      const finance = this.financeList.filter(v => v.finance_code === fileUploadName)[0]

      if (!finance) {
        this.gDisplayToast(
          'ไม่พบรหัสไฟแนนซ์ตามชื่อไฟล์อัปโหลด',
          'โปรดเพิ่มไฟแนนซ์ หรือตรวจสอบชื่อไฟล์อีกครั้ง',
          'danger',
        )
        return
      }
      let validDataUpload = true
      this.itemsUpload = [...data].map((v, i) => {
        const { MODELCODE, CODE, YODJUD, DEFAULT } = v

        if (!MODELCODE) {
          validDataUpload = false
        }

        return {
          local_id: i + 1,
          finance_car_model: MODELCODE,
          finance_car_code: CODE,
          finance_car_item_price: YODJUD,
          down_payment: DEFAULT,
          finance_data_id: finance?.id,
          local_finance_preview: finance.finance_name,
        }
      })

      if (!validDataUpload) {
        this.gDisplayToast('โปรดตรวจสอบไฟล์อัปโหลด', 'โมเดลรถบางตัวยังเป็นค่าว่าง', 'danger')
      }
    },
    async onSaveItemsUpload() {
      const confirm = await this.gCheckConfirmV2('ยืนยันเพิ่มเรทไฟแนนซ์')
      if (!confirm) return
      this.itemsUploadLoading = true
      const resp = await this.$http
        .post('api/finance-car-item-data/file', { json_lists: [...this.itemsUpload] })
        .catch(() => null)
      // console.log('onSaveUploadFile', resp)

      if (resp && resp.code === 200) {
        this.rejectItemsUpload = [...resp.data].map(v => {
          const finance = this.financeList.filter(f => f.id === v.finance_data_id)[0]

          return { ...v, has_motorbike_data: false, local_finance_preview: finance.finance_name }
        })
        this.itemsUpload = []
        if (resp.data.length > 0) {
          this.gDisplayToast('โปรดเพิ่มข้อมูลรถ', 'ดูเหมือนว่าเรทบางตัวยังไม่มีข้อมูลรถ', 'danger')
        }
        await this.$refs.refTableRateFinance.reloadTable()
      }

      this.itemsUploadLoading = false
    },
    async onSaveRejectItemsUpload() {
      const someMotorbikeNoData = this.rejectItemsUpload.some(v => v.has_motorbike_data === false)
      if (someMotorbikeNoData) {
        this.gDisplayToast('โปรดตรวจสอบข้อมูลอีกครั้ง', 'ดูเหมือนว่าโมเดลบางรุ่นจะยังไม่มีข้อมูลรถอยู่', 'danger')
        // return
      }

      const confirm = await this.gCheckConfirmV2('ยืนยันเพิ่มเรทไฟแนนซ์')
      if (!confirm) return
      this.rejectItemsLoading = true
      const resp = await this.$http
        .post('api/finance-car-item-data/file', { json_lists: [...this.rejectItemsUpload] })
        .catch(() => null)
      // console.log('onSaveRejectItemsUpload', resp)

      if (resp && resp.code === 200) {
        this.rejectItemsUpload = [...resp.data].map(v => {
          const finance = this.financeList.filter(f => f.id === v.finance_data_id)[0]

          return { ...v, has_motorbike_data: false, local_finance_preview: finance.finance_name }
        })
        if (resp.data.length > 0) {
          this.gDisplayToast('โปรดเพิ่มข้อมูลรถ', 'ดูเหมือนว่าเรทบางตัวยังไม่มีข้อมูลรถ', 'danger')
        }
        await this.$refs.refTableRateFinance.reloadTable()
      }
      this.rejectItemsLoading = false
    },
    addManualRateFinance() {
      // console.log('addManualFinanceRate')
      this.formRateFinance = {}
      this.$refs.refModalFormRateFinance.show()
    },
    async onConfirmModalFormRateFinance(bvEvent) {
      bvEvent.preventDefault()
      const validate = await this.$refs.refFormRateFinance.validateForm()
      if (!validate) return

      const form = this.$refs.refFormRateFinance.getFormData()
      const titleConfirm = form.id ? 'ยืนยันแก้ไขข้อมูลเรทของโมเดล' : 'ยืนยันเพิ่มข้อมูลเรทของโมเดล'
      const confirm = await this.gCheckConfirmV2(`${titleConfirm} ${form.finance_car_model}`)
      if (!confirm) return

      this.$refs.refModalFormRateFinance.hide()
      this.financeRateLoading = true
      const resp = await this.$http
        .post(
          'api/finance-car-item-data/file',
          {
            json_lists: [{ ...form }],
          },
          { useAlert: { successEnable: false } },
        )
        .catch(() => null)
      if (resp && resp.code === 200) {
        if (resp.data.length > 0) {
          this.rejectItemsUpload = [...resp.data].map(v => {
            const finance = this.financeList.filter(f => f.id === v.finance_data_id)[0]

            return { ...v, has_motorbike_data: false, local_finance_preview: finance.finance_name }
          })
          this.gDisplayToast('โปรดเพิ่มข้อมูลรถ', 'ดูเหมือนว่าโมเดลนี้ยังไม่มีข้อมูลรถ', 'danger')
        } else {
          this.gDisplayToast(form.id ? 'แก้ไขข้อมูลสำเร็จแล้ว' : 'เพิ่มข้อมูลสำเร็จแล้ว')
          this.$refs.refTableRateFinance.reloadTable()
        }
      }

      this.financeRateLoading = false
    },
    async onConfirmModalFormMotorbike(bvEvent) {
      bvEvent.preventDefault()
      const validate = await this.$refs.refFormMotorbike.validateForm()
      if (!validate) return

      const form = this.$refs.refFormMotorbike.getFormData()
      this.$refs.refModalFormMotorbike.hide()
      this.rejectItemsLoading = true
      const resp = await this.$http.post('api/finance-car-data', form).catch(() => null)
      // console.log('onConfirmModalFormMotorbike', resp)
      if (resp && resp.code === 200) {
        this.rejectItemsUpload = this.rejectItemsUpload.map(v => {
          if (v.local_id === form.local_id) {
            return { ...v, has_motorbike_data: true }
          }
          return { ...v }
        })
      }
      this.rejectItemsLoading = false
    },
    onClickEditRejectItemUpload(row) {
      const { item } = row

      if (item.has_motorbike_data) {
        this.gDisplayToast('ได้เพิ่มข้อมูลรถไปแล้ว')
        return
      }

      this.formMotorbike = this.createFormObject(item.finance_car_model, item.local_id)
      // console.log('onClickEditRejectItemUpload', item)

      this.$refs.refModalFormMotorbike.show()
    },
    async onClickCloseTableUpload(key) {
      if (!key) return
      const checkConfirm = await this.gCheckConfirmV2('ยกเลิกอัปโหลดเรทไฟแนนซ์')
      if (!checkConfirm) return

      this[key] = []
    },
    async callApiToUploadRateFinance(listData) {
      // console.log('callApiToUploadRateFinance', listData)
      await this.gMockAsync(500)
    },
    onDeleteItemUpload(row) {
      // console.log('onDeleteItemUpload', row)
      const { item } = row
      this.itemsUpload = [...this.itemsUpload].filter(v => v.local_id !== item.local_id)
    },
    async getFinanceList() {
      const resp = await this.$http.get('api/finance-data/get-finance-data-name').catch(() => null)
      if (resp && resp.code === 200) {
        this.financeList = [...resp.data]
      }
    },
    onClickEditRateFinance(row) {
      // console.log('onClickEditRateFinance', row)
      this.formRateFinance = { ...row.item }
      this.$refs.refModalFormRateFinance.show()
    },
    async onClickDeleteRateFinance(row) {
      const { item } = row
      // console.log('onClickDeleteRateFinance', row)
      const confirm = await this.gCheckConfirmV2(`ยืนยันลบข้อมูลเรทของโมเดล ${item.finance_car_model}`)
      if (!confirm) return
      this.financeRateLoading = true
      const resp = await this.$http.delete(`api/finance-car-item-data/${item.id}`).catch(() => null)
      if (resp && resp.code === 200) {
        this.$refs.refTableRateFinance.reloadTable()
      }
      this.financeRateLoading = false
    },
  },
}
</script>

<style lang="scss" scoped></style>
